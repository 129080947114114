<template>
  <div>
    <van-nav-bar
      title="确认订单"
      left-text="返回"
      left-arrow
      @click-left="onClickLeft"
    />
  </div>

  <div class="address">
    <div class="address-module">
      <div class="addressicon">
        <i class="van-icon van-icon-location"></i>
      </div>
      <div class="addressitem" v-if="hasAddressInfo">
        <div class="addresstitle">
          <div class="address-name">{{ addressInfo.consigneeName }}</div>
          <div class="address-mobile">{{ addressInfo.mobile }}</div>
        </div>
        <div class="address-info">{{ addressInfo.address }}</div>
      </div>
      <div class="addresschange" @click="() => (showAddress = true)">
        点击切换
      </div>
    </div>
  </div>
  <div class="clear"></div>
  <div>
    <!-- <van-cell
      :title="'收货地址'"
      :value="hasAddressInfo ? addressInfo.address : ''"
    
      is-link
    />-->
    <van-popup
      v-model:show="showAddress"
      position="right"
      :style="{ height: '100%', width: '100%' }"
    >
      <address-list
        v-model="addressInfo"
        :showOkBtn="true"
        @changeShow="() => (showAddress = false)"
        @changeValue="changeAddress"
      />
    </van-popup>

    <van-card
      :num="counter"
      :price="goodsInfo.salePrice"
      :title="goodsInfo.goodTitle"
      :thumb="goodsInfo.imgUrl"
    >
      <template #tags>
        <van-tag plain type="danger">{{ goodsInfo.goodLabel }}</van-tag>
      </template>
      <template #footer>
        <van-button
          size="small"
          v-if="counter > 1"
          @click="
            () => {
              counter--;
            }
          "
          >-</van-button
        >
        <van-button size="small" @click="() => counter++">+</van-button>
      </template>
    </van-card>
    <van-submit-bar
      :price="getPrice()"
      button-text="立即下单"
      tip-icon="info-o"
      @submit="onSubmit"
    />
  </div>
</template>

<script>
import AddressList from "@/views/About/AddressList.vue";
import { searchGoodInfo } from "@/api/product.js";
import { searchAddress } from "@/api/about.js";
import { onMounted, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import { submitOrder } from "@/api/order.js";
import { Toast } from "vant";
import { useStore } from "vuex";
export default {
  components: { AddressList },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const showAddress = ref(false);
    const store = useStore();
    const counter = ref(1);
    const goodsInfo = ref({});
    const userInfo = ref(null);
    const addressList = ref(null);
    const addressInfo = ref({ addressId: 0 });
    const hasAddressInfo = ref(false);
    onMounted(() => {
      if (route.params.id) {
        searchGoodInfo({ goodId: route.params.id }).then((res) => {
          console.log(res.data, "res");
          goodsInfo.value = res.data;
        });
        userInfo.value = store.getters["user/getUserInfo"];
        searchAddress({
          openId: userInfo.value.openId,
        }).then((res) => {
          addressList.value = res.data.dataList;
          if (addressList.value.length > 0) {
            addressList.value.forEach((element) => {
              if (element.isDefault) {
                addressInfo.value = element;
                hasAddressInfo.value = true;
              }
            });
          }
          // console.log(addressInfo.value, "addressInfo");
        });
      }
    });
    const changeAddress = (item) => {
      console.log(item, "changeAddress");
      addressInfo.value = item;
    };
    // onUpdated(()=>{
    //   console.log(addressInfo,'addressInfo')
    // })
    const getPrice = () => {
      console.log(goodsInfo.value.salePrice * counter.value, "价格");
      return goodsInfo.value.salePrice * counter.value * 100;
    };
    const onClickLeft = () => {
      router.back();
    };

    const onSubmit = () => {
      if (addressInfo.value.addressId === 0) {
        Toast("请先选择地址");
      }
      if (userInfo.value.isLogin) {
        console.log("");
        submitOrder({
          openId: userInfo.value.openId,
          orderType: 0,
          addressId: addressInfo.value.addressId,
          goods: [
            {
              goodId: goodsInfo.value.goodId,
              quantity: counter.value,
            },
          ],
        }).then((res) => {
          console.log(res, "res111");
          if (res.code === 200) {
            if (typeof WeixinJSBridge == "undefined") {
              if (document.addEventListener) {
                document.addEventListener(
                  "WeixinJSBridgeReady",
                  onBridgeReady,
                  false
                );
              } else if (document.attachEvent) {
                document.attachEvent("WeixinJSBridgeReady", onBridgeReady);
                document.attachEvent("onWeixinJSBridgeReady", onBridgeReady);
              }
            } else {
              onBridgeReady(res.data.result);
            }
          } else {
            Toast(res.msg);
          }
        });
      } else {
        Toast("请先登录");
      }
    };

    function onBridgeReady(settings) {
      // eslint-disable-next-line no-undef
      WeixinJSBridge.invoke("getBrandWCPayRequest", settings, function (res) {
        if (res.err_msg === "get_brand_wcpay_request:ok") {
          Toast("支付成功");
          router.push({ path: "/shop/list" });
          // 使用以上方式判断前端返回,微信团队郑重提示：
          //res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
        } else {
          Toast(JSON.stringify(res));
        }
      });
      // eslint-disable-next-line no-undef
      console.log(WeixinJSBridge, "settings ");
    }

    return {
      counter,
      goodsInfo,
      getPrice,
      showAddress,
      hasAddressInfo,
      addressInfo,
      onClickLeft,
      onSubmit,
      changeAddress,
    };
  },
};
</script> 
<style lang="scss" scoped>
.address {
  margin-bottom: 0.7rem;
  padding: 0.5rem;
  border-radius: 0.3rem;
  background-color: #fff;
  line-height: 1.5rem;
}
.address-module {
  display: flex;
  flex-direction: row;
}
.addressicon {
  float: left;
  text-align: center;
  width: 40px;
  line-height: 3rem;
  color: #ff9600;
}
.addressnone {
  float: left;
  font-size: 1.3rem;
  font-weight: bold;
}
.addressitem {
  float: left;
  width: 80%;
}
.addresstitle {
  display: flex;
}
.address-name {
  font-size: 1rem;
  font-weight: bold;
  padding-right: 0.7rem;
}
.address-mobile {
  font-size: 0.9rem;
  font-weight: bold;
}
.address-info {
  font-size: 0.8rem;
  font-weight: bold;
  clear: both;
}
.addresschange {
  float: right;
  text-align: center;
  font-size: 0.8rem;
  color: #ff9600;
  line-height: 3rem;
  width: 70px;
}
.goodimg {
  float: left;
  text-align: center;
}
.goodinfo {
  float: left;
}

::v-deep .van-card__price-integer {
  color: #ff9600;
  font-weight: bold;
  padding-right: 0.3rem;
}
::v-deep .van-card__price-currency {
  color: #ff9600;
  font-size: 0.5rem;
  padding-right: 0.1rem;
  font-weight: normal;
}
.shop-original {
  float: left;
  font-size: 0.7rem;
  font-weight: normal;
  padding-right: 0.3rem;
}
.paytype {
  float: left;
  font-size: 1.3rem;
  font-weight: bold;
}
.payprice {
  float: left;
  text-align: right;
  color: red;
  font-weight: bold;
  font-size: 1.3rem;
  padding-right: 0.3rem;
}
.clear {
  clear: both;
}
::v-deep .van-tag--danger {
  color: #fff;
  border-color: #ff9600;
  background-color: #ff9600;
  border-radius: 0.3rem;
  padding: 0.2rem 0.5rem;
}
::v-deep .van-card__title {
  font-size: 0.9rem;
  line-height: 1.2rem;
  max-height: 2.4rem;
}
</style>